import axios from 'axios';

// 创建axios实例
const service = axios.create({
    //baseURL: 'http://localhost:9090',
    baseURL: 'http://175.178.17.177', //部署用这个
    timeout: 5000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        return response.data; // 直接返回响应体中的data部分
    },
    error => {
        // 对响应错误做点什么
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 处理未授权的情况
                    break;
                case 404:
                    // 处理找不到资源的情况
                    break;
                // 其他状态码处理...
            }
        }
        return Promise.reject(error);
    }
);

export default service;
