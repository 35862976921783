<template>
	<div id="app">
		<div class="box">
			<!-- 左边Logo -->
			<div class="logoBox">
				<img src="./assets/bigLogo.jpg">
			</div>
			
			<!-- 右边菜单  background-color="#66B3FF"   active-text-color="#66B3FF"-->
			<div class="app-menuBox">
				<el-menu :default-active="activeIndex" class="app-menu-content" mode="horizontal"
						 text-color="#000"  active-text-color="#66B3FF" @select="handleSelect" >
					<el-menu-item class="app-menu-item" index="0">{{getIsChinese() ? '首页': 'Home'}}</el-menu-item>
					<el-submenu class="app-menu-item" index="1">
						<template slot="title" style="height: 100%;">
							<span style="font-size: 20px;font-family: 'Arial'" @click="clickProduct">{{getIsChinese() ? '产品中心': 'Product Center'}}</span>
						</template>
						<el-menu-item  v-for="(item, index) in sexList" :index="'1-'+(index+1)" :key="index">{{getIsChinese() ? item.nameCn: item.nameEn}}</el-menu-item>
					</el-submenu>
					<el-menu-item class="app-menu-item" index="2">{{getIsChinese() ? '关于我们': 'About Us'}}</el-menu-item>
					<el-menu-item class="app-menu-item" index="3">{{getIsChinese() ? '联系我们': 'Contact Us'}}</el-menu-item>
				</el-menu>
				<div class="myLine"></div>
			</div>
			
			<!-- 语言切换 -->
			<div class="languageBox">
				<div class="cBox" @click="languageToChinese">
					<div class="img-box">
						<img src="./assets/Chinese.png" style="width: 106%">
					</div>
					<div style="margin-bottom: 3px">简体中文</div>
				</div>

				<div class="eBox" @click="languageToEnglish">
					<div class="img-box">
						<img src="./assets/English.png">
					</div>
					<div>English</div>
				</div>
			</div>
		</div>
		
		<router-view></router-view>
		
		<!--part004:底部的联系我们-->
		<div class="app-touch-box">
			<div class="app-touch-box-text-box">
				<div>{{getIsChinese() ? '地址: '+this.comInfo.addressCn : 'Add: '+this.comInfo.addressEn}}</div>
				<div>{{getIsChinese() ? '邮编: '+this.comInfo.postCode : 'Post Code: ' +this.comInfo.postCode}}</div>
				<div>{{getIsChinese() ? '电话: '+this.comInfo.phone : 'Tel: ' + this.comInfo.phone}}</div>
				<div>{{getIsChinese() ? '邮箱: '+this.comInfo.email : 'Email: ' +this.comInfo.email}}</div>
				<div style="font-size: 14px; margin-top: 10px">
					Copyright © 2017-2020 中山市中化储运装备有限公司  粤ICP备2020109093号 | http://beian.miit.gov.cn
				</div>
				
			</div>
		</div>
		
		<!-- 回到顶部按钮 -->
		<div class="back-to-top-button" @click="buttonToTop">
			<img src="./assets/icon/icon-to-top.png" style="width: 35px; height: 30px">
		</div>
	
	</div>
</template>

<script>

import myHttp from "@/util/myHttp";

export default {
	data() {
		return {
			sexList:[],
			comInfo:{}
		}
	},
	mounted() {
		this.myRefresh();//用户点刷新时会执行此方法
		this.getSexList();//不知道APP与Index谁更先加载，所以两个文件内都要请求
		this.getComInfo();//不知道APP与Index谁更先加载，所以两个文件内都要请求
	},
	computed: {
		activeIndex() {
			return this.$store.getters.getActiveIndex.toString();  // 确保 age 是字符串类型
		}
	},
	methods: {
		//用户刷新，就回到首页
		myRefresh(){
			this.$store.dispatch('updateActiveIndex', '0');//同步到store
			this.$store.dispatch('updateDetailShow', false);//同步到store
			
			if(this.$router.currentRoute.fullPath !== '/index/Index'){
				this.$router.push('/index/Index');
				setTimeout(()=>{
					const element = document.getElementById('app');
					if (element) {
						element.scrollIntoView({
							behavior: 'auto'  //这是设置滚动速度为快速
						});
					}
				},300)
			}
		},
		
		//【从本地】获取当前导航下标
		getActiveIndex(){
			return this.$store.state.activeIndex
		},
		//【从本地】获取当前语言是否是中文
		getIsChinese() {
			return this.$store.state.isChinese
		},
		//语言切换存入本地
		languageToChinese(){
			this.$store.dispatch('updateIsChinese', true);
		},
		languageToEnglish() {
			this.$store.dispatch('updateIsChinese', false);
		},
		
		//点击了 "产品中心"
		clickProduct(){
			if(this.$router.currentRoute.fullPath === '/product/Product'){
				return;
			}
			this.$store.dispatch('updateDetailShow', false);//同步到store
			this.$store.dispatch('updateActiveIndex', '1-1');//同步到store
			this.$router.push('/product/Product');
		},
		
		handleSelect(key, keyPath) {
			//设置产品页面的小导航
			const lastChar = key.substr(-1);//获取最后一个字符，原字符是1-1, 1-2, 1-3, 1-4, 1-5, 1-6
			const subKey = key.endsWith('3')||key.endsWith('6') ? lastChar+'-1-1' : lastChar+"-1";//第三、六组的第一个元素是有子集的，所以要拼上"-1"
			this.$store.dispatch('updateDetailShow', false);//同步到store
			this.$store.dispatch('updateSubActiveIndex', subKey);//同步到store
			
			if(this.getActiveIndex() == key){
				return
			}
			if (key == 0) {
				this.$router.push('/index/Index');
			} else if (key == 2) {
				this.$router.push('/me/Me');
			} else if (key == 3) {
				this.$router.push('/touch/Touch');
			}else{
				if(this.getActiveIndex().startsWith('1-')){
					return
				}
				this.$router.push('/product/Product');
			}
			
			//设置顶部共用的导航。。。这不个要移到顶上去了
			this.$store.dispatch('updateActiveIndex', key+'');//同步到store
		},
		
		buttonToTop() {
			// 平滑滚动到页面顶部
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		
		
		/**
		 * http://localhost:9090/api/category/level
		 * 顶部需要它生成主导航
		 * 首页需要它生成六大产品分称及图片
		 */
		async getSexList(){
			this.sexList  = this.$store.state.sexList;
			if(this.sexList.length==0){
				try{
					const response = await myHttp.get('/api/category/level');
					if(response.code === 200){
						this.sexList = response.data;
						this.$store.dispatch('updateSexList', this.sexList);//同步到store
						console.log(this.sexList)
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
		/**
		 * 获取公司信息
		 * http://localhost:9090/api/home/company/intro
		 */
		async getComInfo(){
			this.comInfo = this.$store.state.comInfo;
			if(this.comInfo == null){
				try{
					const response = await myHttp.get('/api/home/company/intro');
					if(response.code === 200){
						this.comInfo = response.data;
						this.$store.dispatch('updateComInfo', this.comInfo);//同步到store
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
		
	},
}
</script>


<style>
#app {
	font-family: Arial, Avenir, Helvetica,sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

/*顶部长条*/
.box {
	display: flex;
	justify-content: space-between; /* 在左右两端对齐 */
	//align-items: center; /* 垂直居中 */
	width: 100%;
	height: 90px;
	background-color:white; /*#66B3FF*/
	box-sizing: border-box;
	margin-bottom: 2px;/*消边用*/
}

/*******************************************************************************/
/*公司图标 + 名称*/
.logoBox{
	display:flex;
	justify-content:left;
	width: 300px;
}

.logoBox img{
	width: 300px;
	height: 50px;
	margin-top: 20px;
}

/****菜单***************************************************************************/
.app-menuBox{
	width: 55vw;
	height: 90px;
	background-color: yellow;/*这个背景色对菜单条是无效的，到时删除*/
}
.app-menuBox .app-menu-content{
	height: 100%;
	background-color:white; /*#66B3FF*/
	justify-items: flex-end;
}

.app-menuBox .app-menu-content .app-menu-item{
	height: 100%;
	font-size: 20px;
	padding-top: 20px;
}

/*这个是用来挡住菜单下面的下划线*/
.myLine{
	height: 0px;/*5*/
	width: 100%;
	background-color: white;
	transform: translateY(-1000%);
}

/*************************************************************/
.languageBox{
	height: 90px;
	width: 240px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cBox{
	width: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.eBox{
	width: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.languageBox .img-box{
	width: 30px;
	margin: 5px 5px 5px 15px;
}
.languageBox .img-box img{
	width: 100%;
}

/****联系我们***************************************************************************/
.app-touch-box{
	background-color: #607B8B;
	border-top: 1px #758893 solid;
	height: 200px;
	width: 100%;
}

.app-touch-box-text-box{
	margin-top: 30px;
	color: white;
	font-size: 18px;
}

/******回到顶部***********************************************************************************/
.back-to-top-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	padding: 10px 10px;
	background-color: orange;
	border-radius: 50%;
	cursor: pointer;
	z-index: 9999; /* 确保按钮在其他元素之上 */
	opacity: 0.8;
	transition: opacity 0.3s;
}

.back-to-top-button:hover {
	opacity: 1;
}


</style>
