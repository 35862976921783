<template>
	<div class="me">
		<!--part001:顶部大图-->
		<div class="top-image-box">
			<img class="img" src="../../assets/about/top.jpg">
		</div>
		<!-- part002: 公司介绍 -->
		<div class="company-box">
			<div class="big-line-m">
				<span class="big-line-text-m"> {{getIsChinese() ? '公司介绍': 'About ZZTC' }}</span>
			</div>
			<div class="company-content">
				<el-card class="company-tu">
					<el-image :src="this.companyImage" class="company-tu-img"></el-image>
				</el-card>
				<el-card class="company-wen">
					<pre v-if="getIsChinese()" id="longTextCh">{{this.comInfo.shopDescCn}}</pre>
					<pre v-else id="longTextEn">{{this.comInfo.shopDescEn}}</pre>
				</el-card>
			</div>
		</div>
		
		<!-- part003: 企业文化 -->
		<div class="mission-box">
			<div class="big-line-m">
					<span class="big-line-text-m"> {{getIsChinese() ? '企业文化': 'Our Culture & Values' }}</span>
			</div>
			<div class="mission-wen-wen" style="display: flex; justify-content: space-between; padding: 50px">
				<img src="@/assets/about/yj01.png" style="width: 30%; ">
				<img src="@/assets/about/yj02.png" style="width: 30%; ">
				<img src="@/assets/about/yj03.png" style="width: 30%; ">
			</div>
		</div>
		
	</div>
</template>

<script>
import myHttp from "@/util/myHttp";

export default {
	data() {
		return {
			isChinese: this.$store.state.isChinese,
			comInfo:{},
			companyImage: require('../../assets/about/face.jpg'),
		}
	},
	//页面初始化会执行
	mounted() {
		this.getComInfo();		//获取公司信息
	},
	methods: {
		getIsChinese() {
			return this.$store.state.isChinese
		},
		/**
		 * 获取公司信息
		 * http://localhost:9090/api/home/company/intro
		 */
		async getComInfo(){
			this.comInfo = this.$store.state.comInfo;
			if(this.comInfo == null){
				try{
					const response = await myHttp.get('/api/home/company/intro');
					if(response.code === 200){
						this.comInfo = response.data;
						this.$store.dispatch('updateComInfo', this.comInfo);//同步到store
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
	},
	
}
</script>

<style>
/****栏目大长条***************************************************************************/
.big-line-m{
	width: 100%;
	height: 60px;
	//background-color: #D2E9FF;
	//padding-top: 30px;
	margin: 40px -20px 0px -20px;
	text-align: left;
}
.big-line-text-m{
	font-weight: 800;
	font-size: 28px;
	margin-left: 20px;
}

/*******************************************************************************/
.me .top-image-box{
	width: 100%;
}

.me .top-image-box .img{
	width: 100%;
}

/****公司介绍***************************************************************************/
.company-content{
	border-radius: 5px;
	margin-bottom: 50px;
	margin-top: -15px;
	padding: 40px 20px;
	background-color: #A7B5C6;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 阴影样式 */
	display: flex; /* 使用Flexbox布局 */
}

.company-wen{
	height: 625px;
	width: 840px;
	
	display: inline-block;
	text-align: left; /* 文本靠左对齐 */
	color: #00449D;/**/
	font-weight: 100;
	border: 2px red solid;
}

.company-tu{
	height: 625px;
	box-sizing: border-box;
	border: 0px blue solid;
	margin-right: 20px;
	
	flex: 1; /* 左边盒子将扩展以填充剩余空间 */
	display: flex;/*让子元素即视频本身上下左右都居中*/
	justify-content: center; /* 水平居中 */
	align-items: center;     /* 垂直居中 */
}


#longTextCh{
	white-space: pre-wrap;       /* 保留空白符序列，但是正常地进行文字环绕 */
	word-wrap: break-word;       /* 在长单词或 URL 地址内部进行换行 */
	overflow-wrap: break-word;   /* 对于不支持 word-wrap 的浏览器使用 */
	margin-top: 0;
	font-size: 16px;
	line-height:33px;
}
#longTextEn{
	white-space: pre-wrap;       /* 保留空白符序列，但是正常地进行文字环绕 */
	word-wrap: break-word;       /* 在长单词或 URL 地址内部进行换行 */
	overflow-wrap: break-word;   /* 对于不支持 word-wrap 的浏览器使用 */
	margin-top: 0;
	font-size: 16px;
	line-height:25px;
	font-family: Arial;
}

.company-tu-img{
	/*object-fit: contain;使图片完全可见并保持比例 */
}

/****企业文化***************************************************************************/
.mission-box{
	box-sizing: border-box;
}

.mission-box .mission-wen-wen{
	border-radius: 5px;
	margin-bottom: 50px;
	margin-top: -15px;
	padding: 50px 20px;
	background-color: #A7B5C6;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 阴影样式 */
}

</style>
