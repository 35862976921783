<template>
	<div class="index-box">
		<!-- part001: 轮播图 -->
		<div class="block">
			<el-carousel :interval="3000" arrow="always" height="650px" indicator-position="inside">
				<el-carousel-item v-for="(item,index) in this.bannerList" :key="index">
					<div class="item">
						<img :src="item.url">
							<!--图片上的文字，现在不用-->
							<!--<div class="image-text">-->
								<!--<span style="color: white">{{getIsChinese() ? item.adTextCn : item.adTextEn}}</span>-->
							<!--</div>-->
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		
		<!-- part002: 左视频+右简介-->
		<div class="big-line" style="margin-top: 0px !important;">
			<span class="big-line-text"> {{getIsChinese() ? '公司简介' : 'Company Introduction' }}</span>
		</div>
		<div v-if="!!this.comInfo" class="videoAndText">
			<!-- 左边的视频 -->
			<div class="video-box">
				<div class="video-container">
					<video controls width="100%" autoplay muted>
						<source :src="myVideoUrl" type="video/mp4">
						您的浏览器不支持 video 标签。
					</video>
				</div>
			</div>
			<!-- 右边的文字 -->
			<div class="text-box">
				<div class="text-container">
					<div>
						<img src="../../assets/index/icon-wwd.png" style="width: 70px; height:70px; margin-top: 20px">
					</div>
					<h3>{{ getIsChinese() ? '公司简介' : 'Company Introduction' }}</h3>
					<p id="longText">{{getIsChinese() ? this.comInfo.shopDescCn.substring(0,180)+" ... ..." : this.comInfo.shopDescEn.substring(0,400)+" ... ..."}}</p>
					<a href="#" @click="goToMe">{{ getIsChinese() ? ' + 更多 >>' : ' + Read more >>' }}</a>
				</div>
			</div>
		</div>
		
		<!-- part003: 产品简介-->
		<div class="big-line">
			<span class="big-line-text"> {{getIsChinese() ? '产品' : 'Products' }}</span>
		</div>
		<div class="productBox">
				<div v-for="(item, index) in this.sexList" :key="index">
					<div class="product-item" @click="goToProduct(index+1)">
						<div class="item-img-box">
							<img :src="item.iconUrl"/>
						</div>
					</div>
					<div class="item-text-box">
						<span>{{getIsChinese() ? item.nameCn : item.nameEn}}</span>
					</div>
				</div>
		</div>

		<!-- part004: 认证-->
		<div class="auth-bottom-box">
			<div class="authBox">
				<div class="slider-container">
					<div class="slider-track" :style="{ animationDuration: `${totalDuration}s` }">
						<div v-for="(item, index) in this.doubleCards" :key="index">
							<el-card class="img-box">
								<div class="img-border">
									<img :src="item.url">
								</div>
							</el-card>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import myHttp from "@/util/myHttp";
import {mapGetters} from "vuex";

export default {
	data() {
		return {
			bannerList: [],//首页轮播图
			authList:[], //谁证图
			sexList: [], //六大产品家族
			comInfo: null, //公司信息
			//myVideoUrl:  require('../../assets/index/myVideo.mp4'),
			myVideoUrl: '/profile/upload/media/myVideo.mp4'
		}
	},
	computed: {
		//认证的滚动时间
		totalDuration() {
			return this.authList.length * 8;// 总共的动画持续时间
		},
		doubleCards() {
			// 重复一次卡片数组以实现无缝滚动
			return [...this.authList, ...this.authList, ...this.authList, ...this.authList, ...this.authList, ...this.authList];
		},
		// 使用 mapGetters 将 getters 映射到计算属性
		...mapGetters({
			cidProductMap: 'getCidProductMap' // 假设 getter 名称为 getCidProductMap
		}),
	},
	
	//页面初始化会执行
	mounted() {
		this.init();
	},
	
	methods: {
		async init(){
			await this.getSexListFromStore();	//从本地或远程获取六大家族的顶族，本页需要它来做页面的展示
			await this.getBannerList();	//获取首页的轮播图
			await this.getAuthList();		//获取认证的图片
			await this.getComInfo();		//获取公司信息
			await this.getGoodsPre();     //提前获取产品数据
		},
		
		/**
		 * 从本地或远程取出六大家族的顶族，用于首页中间的展示
		 */
		async getSexListFromStore(){
			this.sexList  = this.$store.state.sexList;
			if(this.sexList.length==0){
				try{
					const response = await myHttp.get('/api/category/level');
					if(response.code === 200){
						this.sexList = response.data;
						this.$store.dispatch('updateSexList', this.sexList);//同步到store
						console.log(this.sexList)
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
		
		/**
		 * 获取首页的轮播图
		 * http://localhost:9090/api/home/ad/carousel
		 * @returns {Promise<void>}
		 */
		async getBannerList(){
			this.bannerList = this.$store.state.bannerList;
			if(this.bannerList.length==0){
				try{
					const response = await myHttp.get('/api/home/ad/carousel');
					if(response.code === 200){
						this.bannerList = response.data;
						this.$store.dispatch('updateBannerList', this.bannerList);//同步到store
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
		
		/**
		 * 获取认证的图片
		 * http://localhost:9090/api/home/auth/file/certifications
		 */
		async getAuthList(){
			this.authList = this.$store.state.authList;
			if(this.authList.length==0){
				try{
					const response = await myHttp.get('/api/home/auth/file/certifications');
					if(response.code === 200){
						this.authList = response.data;
						this.$store.dispatch('updateAuthList', this.authList);//同步到store
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
		
		/**
		 * 获取公司信息
		 * http://localhost:9090/api/home/company/intro
		 */
		async getComInfo(){
			this.comInfo = this.$store.state.comInfo;
			if(this.comInfo == null){
				try{
					const response = await myHttp.get('/api/home/company/intro');
					if(response.code === 200){
						this.comInfo = response.data;
						this.$store.dispatch('updateComInfo', this.comInfo);//同步到store
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
		
		/**
		 * 在首页提前获取产品数据，2秒钟获取6次，
		 */
		 getGoodsPre(){
			let i = 0;
			let timer = setInterval(async ()=>{
				let cid = this.sexList[i].id;//家族ID
				const product = this.cidProductMap[cid];//去本地取你要的家族，如果没有取到，就向远程请求
				if(!product){
					try{
						const response = await myHttp.get('/api/goods/categorys/goods/'+cid);
						if(response.code === 200){
							const resProduct = response.data[0].subCategoryGoods;
							this.$store.dispatch('updateCidProduct', { cid: cid, product: resProduct });//存入本地map
						}
					}catch (error){
						console.error('请求失败:', error);
					}
				}
				
				i++;
				if(i>=6){
					clearInterval(timer)
				}
			},500)
		},
		
		
		/**
		 * ---------------------------------------------------------------------------------------------------------
		 */
		
		goToMe(){
			this.$router.push('/me/Me');
			//设置顶部共用的导航。。。这不个要移到顶上去了
			this.$store.dispatch('updateActiveIndex', '2');//同步到store
		},
		
		/**
		 * 获取语言类型是否是中文
		 * @returns {boolean}
		 */
		getIsChinese(){
			return this.$store.state.isChinese
		},
		
		/**
		 * 点击首页的6个产品示意图跳转到产品页面
		 */
		goToProduct(index){
			//设置顶部共用的导航
			this.$store.dispatch('updateActiveIndex', '1-1');//同步到store
			//设置产品页面的小导航
			const key = index==3||index==6 ? index+='-1-1' : index+"-1";//第三、六组的第一个元素是有子集的，所以要拼上"-1"
			
			this.$store.dispatch('updateSubActiveIndex', key);//同步到store
			this.$router.push('/product/Product');
		}
	}
}
</script>


<style>

/****轮播图***************************************************************************/
.index-box .block{
	//height: 650px;
	box-sizing: border-box;
}

.index-box .block .item{
	height: 100%;
}

.index-box .block .item img{
	width: 100%;
	//height: 100%;
}

/****栏目大长条***************************************************************************/
.big-line{
	height: 60px;
	background-color: #D2E9FF;/*#ECF5FF*/
	padding-top: 20px;
	margin-bottom: 10px;
	margin-top: 30px;
}
.big-line-text{
	font-weight: 800;
	font-size: 38px;
}


/*******************************************************************************/
/*视频+文本 的容器*/
.videoAndText {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: flex-start; /* 使内容顶部对齐 */
}

/*视频容器*/
.videoAndText .video-box{
	height: 500px;
	box-sizing: border-box;
	border: 1px #A7B5C6 solid;/*消白边*/
	
	background-color: #A7B5C6;
	border-radius: 5px;
	margin: 0 30px;
	padding: 15px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 阴影样式 */
	
	display: flex;/*让子元素即视频本身上下左右都居中*/
	justify-content: center; /* 水平居中 */
	align-items: center;     /* 垂直居中 */
	
	//flex: 1;
	width: 40%;
}

.video-box:hover {
	box-shadow: 6px 6px 12px rgba(100, 180, 255, 0.6); /* 高强度悬停阴影样式 */
}

/*文本容器*/
.videoAndText .text-box{
	height: 500px;
	padding: 0 34px;
	border-radius: 5px;
	margin: 0 30px;
	background-color: #A7B5C6;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 阴影样式 */
	width: 35%;
}

.text-box:hover {
	box-shadow: 6px 6px 12px rgba(100, 180, 255, 0.6); /* 高强度悬停阴影样式 */
}

.video-container {
	//flex: 1;
}

.text-container {
	//flex: 2;
	//max-width: 600px;
	font-size: 1rem;
	color: #00449D;
}

video {
	width: 100%;
	height: auto;
}

/*公司简界的长文本*/
#longText {
	text-align: left; /* 文本靠左对齐 */
	font-size: 1vw;  /* 字体大小 */
	line-height: 2.2; /* 行高 */
	margin-bottom: 20px; /* 底部外边距 */
	color: #00449D;
	word-break: break-all; /* 允许单词内部换行 */
}

/****产品展示***************************************************************************/
.productBox {
	display: flex;
	align-items: center;/*让子元素上下居中*/
	flex-wrap: wrap;/*让子元素会自动换行*/
	justify-content: space-between; /* 子元素之间和与边缘之间的间距 */
	
	gap: 2vw; /* 使用gap属性为子元素之间添加间距 */
	width: 100%;
	box-sizing: border-box;
	background-color: #A7B5C6;
	padding: 10px 14% 20px 14%;
}

.productBox .product-item {
	height: 180px;/*原280*/
	width: 300px;/*原420*/
	padding: 10px 10px;
	margin-bottom: 10px;
	margin-top: 30px;
	
	background-color: white;
	border-radius: 8px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 阴影样式 */
	
	display: flex;/*让子元素即视频本身上下左右都居中*/
	justify-content: center; /* 水平居中 */
	align-items: center;     /* 垂直居中 */
}

.productBox .product-item:hover {
	font-size: larger;
	box-shadow: 6px 6px 12px rgba(100, 180, 255, 0.6); /* 高强度悬停阴影样式 */
}

.productBox .product-item img{
	height: 180px;/*原280px*/
	//width: 300px;
	object-fit: contain; /* 使图片完全可见并保持比例 */
	margin-bottom: 10px;
	margin-top: 10px;
}

.productBox .item-text-box{
	font-weight: 600;
	color: #00449D;/*文字颜色*/
	margin-bottom: 10px;
}


/****认证展示***************************************************************************/
.auth-bottom-box{
	width: 100%;
	height: 240px;
	background-color: #607B8B;
	padding-top: 30px;
}

.auth-bottom-box .authBox{
	width: 85%;
	margin: auto;
}

.auth-bottom-box .slider-container {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.auth-bottom-box .slider-track {
	display: flex;
	animation: scroll linear infinite;
	white-space: nowrap;
}

.auth-bottom-box .authBox .img-box {
	min-width: 120px;/* 每个卡片的最小宽度 */
	margin-right: 80px; /* 卡片之间的间距 */
	//padding: 20px;
	box-sizing: border-box;
	height: 180px;
	width: 180px;
	border-radius: 90px;
	//box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 阴影效果 */
	//transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡效果 */
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 10px goldenrod solid;
}

.auth-bottom-box .authBox .img-box .img-border{
	height: 150px;
	width: 150px;
	padding: 25px;
	border-radius: 90px;
	border: 3px goldenrod dotted;
	box-sizing: border-box;
	
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.auth-bottom-box .img-box img{
	width: 100%;
	height: 100%;
	object-fit: contain; /* 保持图片比例，同时在容器内完整显示 */
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-50%);
	}
	100% {
		transform: translateX(-100%);
	}
}

</style>
