var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"touch-box"},[_vm._m(0),_c('div',{staticClass:"mid-box"},[_c('div',{staticClass:"mid-left-box"},[_c('div',{staticClass:"mid-left-item"},[_vm._m(1),_c('div',{staticClass:"item-b"},[_vm._v(_vm._s(_vm.getIsChinese() ? '地址': 'Address'))]),_c('div',{staticClass:"item-c",staticStyle:{"margin-top":"-5px"}},[_vm._v(_vm._s(_vm.getIsChinese() ? this.comInfo.addressCn: this.comInfo.addressEn))])]),_c('div',{staticClass:"mid-left-item"},[_vm._m(2),_c('div',{staticClass:"item-b"},[_vm._v(_vm._s(_vm.getIsChinese() ? '邮编': 'Post Code'))]),_c('div',{staticClass:"item-c"},[_vm._v(_vm._s(this.comInfo.postCode))])]),_c('div',{staticClass:"mid-left-item"},[_vm._m(3),_c('div',{staticClass:"item-b"},[_vm._v(_vm._s(_vm.getIsChinese() ? '电话': 'Tel'))]),_c('div',{staticClass:"item-c"},[_vm._v(_vm._s(this.comInfo.phone))])]),_c('div',{staticClass:"mid-left-item"},[_vm._m(4),_c('div',{staticClass:"item-b"},[_vm._v(_vm._s(_vm.getIsChinese() ? '邮箱': 'Email'))]),_c('div',{staticClass:"item-c"},[_vm._v(_vm._s(this.comInfo.email))])])]),_vm._m(5)]),_c('div',{staticClass:"auth-bottom-box"},[_c('div',{staticClass:"authBox"},[_c('div',{staticClass:"slider-container"},[_c('div',{staticClass:"slider-track",style:({ animationDuration: `${_vm.totalDuration}s` })},_vm._l((this.doubleCards),function(item,index){return _c('div',{key:index},[_c('el-card',{staticClass:"img-box"},[_c('div',{staticClass:"img-border"},[_c('img',{attrs:{"src":item.url}})])])],1)}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-image-box"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/touch/top.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-a"},[_c('img',{attrs:{"src":require("../../assets/icon/icon-1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-a"},[_c('img',{attrs:{"src":require("../../assets/icon/icon-2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-a"},[_c('img',{attrs:{"src":require("../../assets/icon/icon-3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-a"},[_c('img',{attrs:{"src":require("../../assets/icon/icon-4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mid-right-box"},[_c('div',{staticStyle:{"width":"100%","height":"500px"},attrs:{"id":"mapContainer"}})])
}]

export { render, staticRenderFns }