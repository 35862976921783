import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import IndexView from '../views/index/Index.vue'
import MeView from '../views/me/Me.vue'
import ProductView from '../views/product/Product.vue'
import TouchView from '../views/touch/Touch.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/index/Index',
    name: 'Index',
    component: IndexView
  },
  {
    path: '/product/Product',
    name: 'Product',
    component: ProductView
  },
  {
    path: '/me/Me',
    name: 'Me',
    component: MeView
  },
  {
    path: '/touch/Touch',
    name: 'Touch',
    component: TouchView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
