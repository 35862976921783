<template>
	<div class="touch-box">
		<!--part001:顶部大图-->
		<div class="top-image-box">
			<img class="img" src="../../assets/touch/top.jpg">
		</div>
		
		<!--part002:中间大盒子-->
		<div class="mid-box">
			<!--左边盒子放四个元素-->
			<div class="mid-left-box">
				<div class="mid-left-item">
					<div class="item-a">
						<img src="../../assets/icon/icon-1.png">
					</div>
					<div class="item-b">{{getIsChinese() ? '地址': 'Address'}}</div>
					<div class="item-c" style="margin-top: -5px">{{getIsChinese() ? this.comInfo.addressCn: this.comInfo.addressEn}}</div>
				</div>
				<div class="mid-left-item">
					<div class="item-a">
						<img src="../../assets/icon/icon-2.png">
					</div>
					<div class="item-b">{{getIsChinese() ? '邮编': 'Post Code'}}</div>
					<div class="item-c">{{this.comInfo.postCode}}</div>
				</div>
				<div class="mid-left-item">
					<div class="item-a">
						<img src="../../assets/icon/icon-3.png">
					</div>
					<div class="item-b">{{getIsChinese() ? '电话': 'Tel'}}</div>
					<div class="item-c">{{this.comInfo.phone}}</div>
				</div>
				<div class="mid-left-item">
					<div class="item-a">
						<img src="../../assets/icon/icon-4.png">
					</div>
					<div class="item-b">{{getIsChinese() ? '邮箱': 'Email'}}</div>
					<div class="item-c">{{this.comInfo.email}}</div>
				</div>

			</div>
			
			<!--右边盒子放地图-->
			<div class="mid-right-box">
				<div id="mapContainer" style="width: 100%; height: 500px"></div>
			</div>
		</div>
		
		<!--part003:底部的认证滚动-->
		<div class="auth-bottom-box">
			<div class="authBox">
				<div class="slider-container">
					<div class="slider-track" :style="{ animationDuration: `${totalDuration}s` }">
						<div v-for="(item, index) in this.doubleCards" :key="index">
							<el-card class="img-box">
								<div class="img-border">
									<img :src="item.url">
								</div>
							</el-card>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import myHttp from "@/util/myHttp";

export default {
	data(){
		return{
			comInfo:{},
			authList:[], //认证图
		}
	},
	
	//页面初始化会执行
	mounted() {
		this.getAuthList();
		this.getComInfo();
		this.initMap();
	},
	methods: {
		/**初始化地图*/
		initMap() {
			// 创建地图实例
			const map = new TMap.Map(document.getElementById('mapContainer'), {
				center: new TMap.LatLng(22.507582,113.505080), // 设置中心点坐标
				zoom: 18,                                      // 设置地图缩放级别
			});
			// 添加标记点（图标）
			const marker = new TMap.MultiMarker({
				map,
				styles: {
					marker: new TMap.MarkerStyle({
						width: 25,height: 35,
						anchor: { x: 16, y: 32 },
						src: 'https://example.com/path/to/your/icon.png', // 替换为你的图标URL
					}),
				},
				geometries: [{
					id: 'demo',
					styleId: 'marker',
					position: new TMap.LatLng(22.507582,113.505080), // 与中心点坐标相同
				}],
			});
		},
		
		getIsChinese() {
			return this.$store.state.isChinese
		},
		
		/**
		 * 获取认证的图片
		 * http://localhost:9090/api/home/auth/file/certifications
		 */
		async getAuthList(){
			this.authList = this.$store.state.authList;
			if(this.authList.length==0){
				try{
					const response = await myHttp.get('/api/home/auth/file/certifications');
					if(response.code === 200){
						this.authList = response.data;
						this.$store.dispatch('updateAuthList', this.authList);//同步到store
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
		/**
		 * 获取公司信息
		 * http://localhost:9090/api/home/company/intro
		 */
		async getComInfo(){
			this.comInfo = this.$store.state.comInfo;
			if(this.comInfo == null){
				try{
					const response = await myHttp.get('/api/home/company/intro');
					if(response.code === 200){
						this.comInfo = response.data;
						this.$store.dispatch('updateComInfo', this.comInfo);//同步到store
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
	},

	computed: {
		//认证的滚动时间
		totalDuration() {
			return this.authList.length * 5;// 总共的动画持续时间，越小，速度越快
		},
		doubleCards() {
			// 重复一次卡片数组以实现无缝滚动
			return [...this.authList, ...this.authList, ...this.authList, ...this.authList, ...this.authList, ...this.authList];
		}
	}
};
</script>

<style>

/*******************************************************************************/
.touch-box .top-image-box{
	width: 100%;
}

.touch-box .top-image-box .img{
	width: 100%;
}

/*******************************************************************************/
.touch-box .mid-box{
	width: 100%;
	height: 600px;
	background-color: #607B8B;
	margin-top: -10px;
	display: flex;
	justify-content: center;/*让子元素左右居中*/
	align-items: center;/*让子元素上下居中*/
}

.touch-box .mid-left-box{
	width: 750px;
	height: 480px;
	background-color: white;
	margin: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	
	display: flex;
	justify-content: center;/*让子元素左右居中*/
	align-items: center;/*让子元素上下居中*/
	flex-wrap: wrap;/*让子元素会自动换行*/
}

.touch-box .mid-right-box{
	width: 45%;
	height: 500px;
	background-color: white;
	margin: 10px;
}

.touch-box .mid-right-box img{
	width: 100%;
	height: 100%;
}

.touch-box .mid-left-item{
	width: 300px;
	height: 200px;
	background-color: gainsboro;
	margin: 20px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 阴影样式 */
	border-radius: 8px;
}

.touch-box .mid-left-item:hover {
	box-shadow: 3px 3px 10px rgba(24, 116, 0205, 0.5); /* 阴影样式 */
}

.touch-box .item-a{
	width: 100%;
	height: 50px;
	margin-top: 40px;
}
.touch-box .item-b{
	width: 100%;
	height: 50px;
}
.touch-box .item-c{
	width: 100%;
	height: 50px;
}

.touch-box .mid-left-item img{
	width: 50px;
	height: 50px;
}

/****认证展示***************************************************************************/
.auth-bottom-box{
	width: 100%;
	height: 240px;
	background-color: #607B8B;
	padding-top: 30px;
}

.auth-bottom-box .authBox{
	width: 85%;
	margin: auto;
}

.auth-bottom-box .slider-container {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.auth-bottom-box .slider-track {
	display: flex;
	animation: scroll linear infinite;
	white-space: nowrap;
}

.auth-bottom-box .authBox .img-box {
	min-width: 120px;/* 每个卡片的最小宽度 */
	margin-right: 80px; /* 卡片之间的间距 */
	//padding: 20px;
	box-sizing: border-box;
	height: 180px;
	width: 180px;
	border-radius: 90px;
	//box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 阴影效果 */
	//transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡效果 */
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 10px goldenrod solid;
}

.auth-bottom-box .authBox .img-box .img-border{
	height: 150px;
	width: 150px;
	padding: 25px;
	border-radius: 90px;
	border: 3px goldenrod dotted;
	box-sizing: border-box;
	
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.auth-bottom-box .img-box img{
	width: 100%;
	height: 100%;
	object-fit: contain; /* 保持图片比例，同时在容器内完整显示 */
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-50%);
	}
	100% {
		transform: translateX(-100%);
	}
}

</style>
