import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    // 存放
    state: {
        isChinese: true,      //语言是中文吗
        activeIndex : '0',    //当前导航下标，请带引号
        subActiveIndex:'1-1', //当前小导航下标，请带引号
        detailShow: false,    //是否显示产品详情（不显示详情时，就显示某一类产品）
        sexList:[],           //六大家族
        level2List:[],        //二级分类
        level3List:[],        //三级分类
        bannerList:[],        //首页轮播图
        authList:[],          //认证
        comInfo: null,        //公司信息
        cidProductMap: {}     // key=家族id, value=家族产品
    },
    // 获取
    getters: {
        getIsChinese: state => state.isChinese,
        getActiveIndex: state => state.activeIndex,
        getSubActiveIndex: state => state.subActiveIndex,
        getDetailShow: state => state.detailShow,
        getSexList: state => state.sexList,
        getBannerList: state => state.bannerList,
        getAuthList: state => state.authList,
        getComInfo: state => state.comInfo,
        getLevel2List: state => state.level2List,
        getLevel3List: state => state.level3List,
        getCidProductMap: state => state.cidProductMap,
        getCidProduct: state => cid => state.cidProductMap[cid]
    },
    // 异步操作或提交多个 mutation
    actions: {
        updateIsChinese({ commit }, value) {
            commit('setIsChinese', value);
        },
        updateActiveIndex({ commit }, value) {
            commit('setActiveIndex', value);
        },
        updateSubActiveIndex({ commit }, value) {
            commit('setSubActiveIndex', value);
        },
        updateDetailShow({ commit }, value) {
            commit('setDetailShow', value);
        },
        updateSexList({ commit }, value) {
            commit('setSexList', value);
        },
        updateBannerList({ commit }, value) {
            commit('setBannerList', value);
        },
        updateAuthList({ commit }, value) {
            commit('setAuthList', value);
        },
        updateComInfo({ commit }, value) {
            commit('setComInfo', value);
        },
        updateLevel2List({ commit }, value) {
            commit('setLevel2List', value);
        },
        updateLevel3List({ commit }, value) {
            commit('setLevel3List', value);
        },
        setCidProductMap({ commit }, payload) {
            commit('SET_CID_PRODUCT_MAP', payload);
        },
        updateCidProduct({ commit }, { cid, product }) {
            commit('UPDATE_CID_PRODUCT', { cid, product });
        },
    },
    //修改(同步)
    mutations: {
        setIsChinese(state, value) {
            state.isChinese = value;
        },
        setActiveIndex(state, value) {
            state.activeIndex = value;
        },
        setSubActiveIndex(state, value) {
            state.subActiveIndex = value;
        },
        setDetailShow(state, value) {
            state.detailShow = value;
        },
        setSexList(state, value) {
            state.sexList = value;
        },
        setBannerList(state, value) {
            state.bannerList = value;
        },
        setAuthList(state, value) {
            state.authList = value;
        },
        setComInfo(state, value) {
            state.comInfo = value;
        },
        setLevel2List(state, value) {
            state.level2List = value;
        },
        setLevel3List(state, value) {
            state.level3List = value;
        },
        // 添加或更新键值对
        SET_CID_PRODUCT_MAP(state, payload) {
            state.cidProductMap = payload;
        },
        UPDATE_CID_PRODUCT(state, { cid, product }) {
            Vue.set(state.cidProductMap, cid, product); // 使用 Vue.set 确保响应性
        },
    },

    modules: {}
})





















