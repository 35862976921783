<template>
	<div v-if="show" class="overlay" @click="closePreview">
		<div class="preview" @click.stop>
			<img :src="imageSrc">
			<button @click="closePreview" class="close-btn">x</button>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		show:{
			type: Boolean,
			required: true
		},
		imageSrc:{
			type: String,
			required: true
		}
	},
	methods:{
		closePreview(){
			this.$emit('close')
		}
	}
}
</script>


<style>
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.preview {
	position: relative;
	box-sizing: border-box;
	width: 90%;
	height: 90%;
	background: #c5cedc;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	justify-content: center;
	align-items: center;
	padding: 100px 100px 50px 100px;
}

.preview img {
	height: 80%;
	width: 80%;
	object-fit: contain; /* 使图片完全可见并保持比例 */
}

.close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	border: none;
	font-size: 1.2em;
	cursor: pointer;
	color: #000;
	background-color: red;/*transparent*/
}
</style>