<template>
	<div class="product-box" id="section-top">
		
		<!-- part001: 产品滚动-->
		<div class="product-run-box">
			<div class="proBox">
				<div class="slider-container">
					<div class="slider-track" :style="{ animationDuration: `${totalDuration}s` }">
						<div v-for="(item, index) in this.doubleCards" :key="index">
							<div class="img-box">
								<div class="img-border">
									<img :src="item.url">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!--part002: 产品小导航  -->
		<div v-if="!!this.sexList && this.sexList.length>=6" class="nav-box">
			<el-menu :default-active="subActiveIndex" mode="horizontal" :unique-opened="true"
					 @select="smallHandleSelect" background-color="#FFF" text-color="#000000" active-text-color="#66B3FF">
				
				<el-submenu index="1" >
					<template slot="title">
						<span class="product-nav-title" @click="clickSmallTitle('1-1')">
							{{getIsChinese() ? this.sexList[0].nameCn: this.sexList[0].nameEn}}
						</span>
					</template>
					<div v-for="(item) in this.groupList[0]">
							<div v-if="item.children.length<1">
								<el-menu-item :index="item.keywordsEn">{{getIsChinese() ? item.nameCn || '默认名称': item.nameEn}}</el-menu-item>
							</div>
							<div v-else>
								<el-submenu :index="item.keywordsEn">
									<template slot="title">{{getIsChinese() ? item.nameCn: item.nameEn}}</template>
									<div v-for="(leaf) in item.children[0]">
										<el-menu-item :index="leaf.keywordsEn">{{getIsChinese() ? leaf.nameCn || '默认名称': leaf.nameEn}}</el-menu-item>
									</div>
								</el-submenu>
							</div>
					</div>
				</el-submenu>
				
				<el-submenu index="2">
					<template slot="title">
						<span class="product-nav-title" @click="clickSmallTitle('2-1')">
							{{getIsChinese() ? this.sexList[1].nameCn : this.sexList[1].nameEn}}
						</span>
					</template>
					<div v-for="(item) in this.groupList[1]">
						<div v-if="item.children.length<1">
							<el-menu-item :index="item.keywordsEn">{{getIsChinese() ? item.nameCn: item.nameEn}}</el-menu-item>
						</div>
						<div v-else>
							<el-submenu :index="item.keywordsEn">
								<template slot="title">{{getIsChinese() ? item.nameCn : item.nameEn}}</template>
								<div v-for="(leaf) in item.children[0]">
									<el-menu-item :index="leaf.keywordsEn">{{getIsChinese() ? leaf.nameCn: leaf.nameEn}}</el-menu-item>
								</div>
							</el-submenu>
						</div>
					</div>
				</el-submenu>
				
				<el-submenu index="3">
					<template slot="title">
						<span class="product-nav-title" @click="clickSmallTitle('3-1')">
							{{getIsChinese() ? this.sexList[2].nameCn : this.sexList[2].nameEn}}
						</span>
					</template>
					<div v-for="(item) in this.groupList[2]">
						<div v-if="item.children.length<1">
							<el-menu-item :index="item.keywordsEn">{{getIsChinese() ? item.nameCn: item.nameEn}}</el-menu-item>
						</div>
						<div v-else>
							<el-submenu :index="item.keywordsEn">
								<template slot="title">{{getIsChinese() ? item.nameCn : item.nameEn}}</template>
								<div v-for="(leaf) in item.children[0]">
									<el-menu-item :index="leaf.keywordsEn">{{getIsChinese() ? leaf.nameCn: leaf.nameEn}}</el-menu-item>
								</div>
							</el-submenu>
						</div>
					</div>
				</el-submenu>
				
				<el-submenu index="4">
					<template slot="title">
						<span class="product-nav-title" @click="clickSmallTitle('4-1')">
							{{getIsChinese() ? this.sexList[3].nameCn : this.sexList[3].nameEn}}
						</span>
					</template>
					<div v-for="(item) in this.groupList[3]">
						<div v-if="item.children.length<1">
							<el-menu-item :index="item.keywordsEn">{{getIsChinese() ? item.nameCn: item.nameEn}}</el-menu-item>
						</div>
						<div v-else>
							<el-submenu :index="item.keywordsEn">
								<template slot="title">{{getIsChinese() ? item.nameCn : item.nameEn}}</template>
								<div v-for="(leaf) in item.children[0]">
									<el-menu-item :index="leaf.keywordsEn">{{getIsChinese() ? leaf.nameCn: leaf.nameEn}}</el-menu-item>
								</div>
							</el-submenu>
						</div>
					</div>
				</el-submenu>
				
				<el-submenu index="5">
					<template slot="title">
						<span class="product-nav-title" @click="clickSmallTitle('5-1')">
							{{getIsChinese() ? this.sexList[4].nameCn : this.sexList[4].nameEn}}
						</span>
					</template>
					<div v-for="(item) in this.groupList[4]">
						<div v-if="item.children.length<1">
							<el-menu-item :index="item.keywordsEn">{{getIsChinese() ? item.nameCn: item.nameEn}}</el-menu-item>
						</div>
						<div v-else>
							<el-submenu :index="item.keywordsEn">
								<template slot="title">{{getIsChinese() ? item.nameCn : item.nameEn}}</template>
								<div v-for="(leaf) in item.children[0]">
									<el-menu-item :index="leaf.keywordsEn">{{getIsChinese() ? leaf.nameCn: leaf.nameEn}}</el-menu-item>
								</div>
							</el-submenu>
						</div>
					</div>
				</el-submenu>
				
				<el-submenu index="6">
					<template slot="title">
						<span class="product-nav-title" @click="clickSmallTitle('6-1')">
							{{getIsChinese() ? this.sexList[5].nameCn : this.sexList[5].nameEn}}
						</span>
					</template>
					<div v-for="(item) in this.groupList[5]">
						<div v-if="item.children.length<1">
							<el-menu-item :index="item.keywordsEn">{{getIsChinese() ? item.nameCn: item.nameEn}}</el-menu-item>
						</div>
						<div v-else>
							<el-submenu :index="item.keywordsEn">
								<template slot="title">{{getIsChinese() ? item.nameCn : item.nameEn}}</template>
								<div v-for="(leaf) in item.children[0]">
									<el-menu-item :index="leaf.keywordsEn">{{getIsChinese() ? leaf.nameCn: leaf.nameEn}}</el-menu-item>
								</div>
							</el-submenu>
						</div>
					</div>
				</el-submenu>

			</el-menu>
		</div>
		
		<!--图片预览-->
		<ImagePreview :show="isPreviewOpen" :image-src="previewImageUrl" @close="closePreview"/>
		
		<!--part003: 一类产品（六分之一）-->
		<!-- 情况零：加载中-->
		<div v-if="!this.currentProductType.length" style="width: 100%; height:200px;">
			<el-table
				v-loading="true"
				element-loading-text="加载中 / loading"
				element-loading-background="rgba(255, 255, 255, 0.8)"
				style="width: 100%; height: 200px">
			</el-table>
		</div>
		<!-- 情况二：看列表-->
		<div v-if="!detailShow && !!this.currentProductType.length" style="width: 100%">
			<div v-for="(item, index) in this.currentProductType" :key="index" class="type-box">
				<!--A：类名盒-->
				<div class="type-name-box" >
					{{getIsChinese() ? item.nameCn : item.nameEn}}
				</div>
				
				<!--B：产品盒-->
				<div style="width: 100%">
					<!--当前是类别，所以要遍历产品-->
					<div class="type-product-box">
						<div v-for="(P, I) in item.goods" :key="I" >
							<div class="p-item-box" :id="'section-'+P.goodsSn" :key="index" @click="getDetailById(P.id)">
								<img :src="P.gallery.split(',')[0]">
								<!--<div>新锚点{{'section-'+P.goodsSn}}</div>-->
							</div>
							<div class="t-item-box">{{getIsChinese() ? P.nameCn : P.nameEn}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 情况三：看详情-->
		<div v-if="detailShow && !!this.currentProductType.length" class="detail-box">
			<h1 style="width: 100%; text-align: left; margin-left: 20px">{{getIsChinese() ? '产品详情': 'Product Details'}}</h1>
			<div class="tu-all-box">
				<div v-for="(item,index) in this.currentProductItem.gallery.split(',')" :key="index" class="tuItem-box" @click="openPreview(item)">
					<div class="item-img-box">
						<img :src="item"/>
					</div>
					<!--每张图片上的字，如果有 并且切分后与图片张数相同，就显示-->
					<div v-if="!!currentProductItem.specificationCn && currentProductItem.specificationCn.split('=').length === currentProductItem.gallery.split(',').length" style="margin-top: 10px">
						<span>{{getIsChinese() ? currentProductItem.specificationCn.split('=')[index] :  currentProductItem.specificationEn.split('=')[index]}}</span>
					</div>
				</div>
				
			</div>
			<div class="product-name" >
				{{getIsChinese() ? currentProductItem.nameCn:currentProductItem.nameEn}}
			</div>
			<div class="product-remark">
				{{getIsChinese() ? currentProductItem.briefCn:currentProductItem.briefEn}}
			</div>
			<div class="product-table-box">
				<div v-for="(item, index) in getIsChinese() ?  cnTable : enTable" :key="item" class="row">
					<div :class="item[0]==='' ? 'cell-left-k' : 'cell-left'">{{item[0]}}</div>
					<!--处理空行-->
					<div :class="item[0]==='' ? 'cell-right-item-k' : 'cell-right-item'" v-for="(IT, IN) in item.slice(1)" :key="IT" >
						{{item[1+IN]}}
					</div>
				</div>
			</div>
		
		</div>

		
	</div>
</template>

<script>
import ImagePreview from "@/components/ImagePreview.vue";
import myHttp from "@/util/myHttp";
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			sexList: [],//在本页面，只用于生成小导航，只需要名字
			level2List:[],
			level3List:[],
			groupList:[],//类别
			currentProductType: [],//当前分类（六分之一）
			currentProductItem: {},//当前查看哪个产品的详情
			currentTable:[], //表格数据
			cnTable:[],
			enTable:[],
			
			//【这里是顶部滚动的产品图】
			topList: [
				{url: require('@/assets/topList/P-A-01.png')},
				{url: require('@/assets/topList/P-A-02.png')},
				{url: require('@/assets/topList/P-A-02-01.png')},
				{url: require('@/assets/topList/P-A-02-02.png')},
				{url: require('@/assets/topList/P-A-02-03.png')},
				{url: require('@/assets/topList/P-A-02-04.png'),},
				{url: require('@/assets/topList/P-A-02-05.png')},
				{url: require('@/assets/topList/P-B-01.png')},
				{url: require('@/assets/topList/P-B-02-01.png')},
				{url: require('@/assets/topList/P-B-03.png')}
			],
			
			//【目标锚点】
			targetSection: 'section-top',
			
			//图片预览相关
			isPreviewOpen: false,
			previewImageUrl: '',
		}
	},

	components:{
		ImagePreview
	},
	
	//页面加载时会执行
	mounted() {
		this.init();
	},
	computed: {
		//认证的滚动时间
		totalDuration() {
			return this.topList.length * 8;// 总共的动画持续时间
		},
		// 重复一次卡片数组以实现无缝滚动
		doubleCards() {
			return [...this.topList, ...this.topList, ...this.topList, ...this.topList, ...this.topList, ...this.topList];
		},
		//小导航
		subActiveIndex() {
			return this.$store.getters.getSubActiveIndex.toString();
		},
		//详情开关
		detailShow(){
			return this.$store.getters.getDetailShow;
		},
		// 使用 mapGetters 将 getters 映射到计算属性
		...mapGetters({
			cidProductMap: 'getCidProductMap' // 假设 getter 名称为 getCidProductMap
		}),
	},
	watch: {
		subActiveIndex(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.switchProduct(newVal)
			}
		}
	},
	methods: {
		 //过10耗秒再发请求，防止页面无数据
		 async init(){
				this.sexList  = this.$store.state.sexList;;//从本地取出六大顶族，本面需要它来生成小导航
				await this.getLevel2List();//23个
				await this.getLevel3List();//22个
				await this.doGroup();//对请求过来的三个级别，进行分组，分进6大类，组成树结构
			 	await this.getGoodsByCid();
		},
		
		getIsChinese() {
			return this.$store.state.isChinese
		},
		
		/**
		 * 对请求过来的三个级别，进行分组，分进6大类，组成树结构
		 */
		doGroup(){
			this.groupList = [];//要先清空，不然可能会有多份
			for (let i = 0; i < this.sexList.length; i++) {
				const CID = this.sexList[i].id;//顶级类ID
				let resultC2List = this.level2List.filter( obj => obj.pid === CID);
				for(let j=0; j<resultC2List.length; j++){
					const cid = resultC2List[j].id;//当前元素
					let resultC3List = this.level3List.filter( o => o.pid === cid);
					if(resultC3List.length!==0){
						resultC2List[j].children.push(resultC3List);//将对应的3级分类 挂到 2级分类树上
					}
				}
				this.groupList.push(resultC2List)//将当前的二级分类持到顶级分类树上
			}
		},
		
		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		
		
		/**
		 * 获取所有的二级分类（参数传1）
		 * http://localhost:9090/api/category/level?level=1
		 */
		async getLevel2List(){
			this.level2List  = this.$store.state.level2List;
			if(this.level2List.length==0){
				try{
					const response = await myHttp.get('/api/category/level?level=1');
					if(response.code === 200){
						this.level2List = response.data;
						this.$store.dispatch('updateLevel2List', this.level2List);//同步到store
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
		
		
		/**
		 * 获取所有的三级分类（参数传2）
		 * http://localhost:9090/api/category/level?level=2
		 */
		async getLevel3List(){
			this.level3List  = this.$store.state.level3List;
			if(this.level3List.length==0){
				try{
					const response = await myHttp.get('/api/category/level?level=2');
					if(response.code === 200){
						this.level3List = response.data;
						this.$store.dispatch('updateLevel3List', this.level3List);//同步到store
					}
				}catch (error){
					console.error('请求失败:', error);
				}
			}
		},
		
		
		/**
		 * 获取产品。。获取某分类id返回旗下所有的分类和产品
		 * http://localhost:9090/api/goods/categorys/goods/{categoryId}
		 * http://localhost:9090/api/goods/categorys/goods/10360013
		 * 10360013 - 10360018
		 */
		async getGoodsByCid(){
			const subActiveIndex = this.$store.getters.getSubActiveIndex.toString();
			let cid = this.sexList?.[0]?.id || 10360013;//默认进入第一家族
			
			//第一步：确定cid 和 小导航
			//>>>>情况一：进入第一家族
			if(subActiveIndex=='0-1' || subActiveIndex=='1-1'){
				this.$store.dispatch('updateSubActiveIndex', '1-1');//同步到store
			}
			//>>>>情况二：进入其他家族
			else{
				const index = parseInt(subActiveIndex.charAt(0),10)-1;//获取第一个字符转换成10进制的数
				cid = this.sexList[index].id;
				this.$store.dispatch('updateSubActiveIndex', subActiveIndex);//同步到store
			}
			
			//第二步：确定数据。。去本地取你要的家族，如果没有取到，就向远程请求
			const product = this.cidProductMap[cid];
			if(!product){
				try{
					const response = await myHttp.get('/api/goods/categorys/goods/'+cid);
					if(response.code === 200){
						const resProduct = response.data[0].subCategoryGoods;
						//存入本地map
						this.$store.dispatch('updateCidProduct', { cid: cid, product: resProduct });
						//渲染数据到页面
						//console.log('===========请求后渲染=================')
						this.currentProductType = resProduct;
					}
				}catch (error){
					//console.error('请求失败:', error);
				}
			}else{
				//渲染数据到页面
				this.currentProductType = this.cidProductMap[cid];
				//console.log('===========直接渲染=================')
			}
		},
		
		
		/**
		 * 根据id获取一个产品的详情
		 * http://localhost:9090/api/goods/detail/{id}
		 * http://localhost:9090/api/goods/detail/1181017
		 */
		async getDetailById(id){
			try{
				const response = await myHttp.get('/api/goods/detail/'+id);
				if(response.code === 200){
					this.currentProductItem = response.data;
					this.formatTable(this.currentProductItem.goodsDetailList);
				}
			}catch (error){
				console.error('请求失败:', error);
			}
			
			this.detailShow = true;
			this.$store.dispatch('updateDetailShow', true);//同步到store
			
			// 快速滚动到页面顶部
			window.scrollTo({
				top: 0,
				behavior: 'auto'
			});
		},
		
		
		/**
		 * 将产品的详情格式化到表格中
		 */
		formatTable(data){
			this.cnTable=[];
			this.enTable=[];
			for(let i=0;i<data.length;i++){
				console.log('i='+i)
				const cnLine = data[i].goodsAttrCn.split('=');
				const enLine = data[i].goodsAttrEn.split('=');
				this.cnTable.push(cnLine);
				this.enTable.push(enLine);
			}
		},
		
		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		
		/**
		 * 用户点击了小导航的六个之一
		 * @param key
		 */
		clickSmallTitle(key){
			key = key.startsWith('3')||key.startsWith('6') ? key+='-1' : key;//第三、六组的第一个元素是有子集的，所以要拼上"-1"
			this.$store.dispatch('updateSubActiveIndex', key);//同步到store
		},
		
		/**
		 * 切换产品类别
		 * @param key
		 */
		switchProduct(key){
			this.$store.dispatch('updateDetailShow', false);//同步到store
			//延迟一点点进行锚点的滚动
			setTimeout(()=>{
				this.scrollToTop()
			},100)
			this.getGoodsByCid();
		},
		
		
		/**
		 * 导航组件自带的方法，用于滚动到该类产品的指定锚点
		 * @param key
		 * @param keyPath
		 */
		smallHandleSelect(key, keyPath){
			this.$store.dispatch('updateDetailShow', false);//同步到store
			this.$store.dispatch('updateSubActiveIndex', key+'');//同步到store
			//延迟一点点进行锚点的滚动
			setTimeout(()=>{
				this.scrollToSection(key)
			},100)
		},
		
		/**
		 * 滚动到指定的锚点
		 * 偏移量：越往下的产品，越要往上移得多
		 */
		scrollToSection(key) {
			let sectionId = 'section-'+ key;
			const element = document.getElementById(sectionId);
			if (element) {
				setTimeout(()=>{
					window.scrollTo({
						top: element.offsetTop-100,
						behavior: 'smooth'
					});
				},100)
			}else{
				//alert("我要去 + 但找不到：" + sectionId)
			}
		},
	
		/**
		 * 滚动到顶部锚点
		 */
		scrollToTop() {
			const element = document.getElementById('section-top');
			if (element) {
				element.scrollIntoView({
					behavior: 'auto'  //这是设置滚动速度为快速
				});
				setTimeout(() => {
					window.scrollBy(0, -100);
				}, 10);  // 在锚点的基础上再向上移动100px。。。延迟 500 毫秒以确保平滑滚动完成
			}
		},
		
		/**
		 * 预览产品大图
		 * @param url
		 */
		openPreview(url) {
			this.previewImageUrl = url,
			this.isPreviewOpen = true;
		},
		closePreview() {
			this.isPreviewOpen = false;
		}
		
	}
}
</script>


<style>
.product-box{
	width: 100%;
	border: 0px red solid;
}

/****产品小导航****************************************************************/
.product-nav-title{
	font-size: 16px;
}

/****产品像认证一样展示***************************************************************************/
.product-run-box{
	width: 100%;
	height: 230px;
	background-color: #A7B5C6;
	padding-top: 50px;
}

.product-run-box .proBox{
	width: 97%;
	margin: auto;
}

.product-run-box .slider-container {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.product-run-box .slider-track {
	display: flex;
	animation: scroll linear infinite;
	white-space: nowrap;
}

.product-run-box .proBox .img-box {
	min-width: 120px;/* 每个卡片的最小宽度 */
	margin-right: 30px; /* 卡片之间的间距 */
	//padding: 20px;
	box-sizing: border-box;
	height: 180px;
	width: 180px;

	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background-color: #A7B5C6;
	border: 1px #c5cedc solid;
	border-radius: 4px;
}

.product-run-box .proBox .img-box .img-border{
	height: 150px;
	width: 150px;
	padding: 10px;
	border-radius: 90px;
	box-sizing: border-box;
	
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.product-run-box .img-box img{
	width: 100%;
	height: 100%;
	object-fit: contain; /* 保持图片比例，同时在容器内完整显示 */
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-50%);
	}
	100% {
		transform: translateX(-100%);
	}
}

/****产品展示***************************************************************************/
/*一类一个盒，里面装着：类名盒 + 产品盒*/
.type-box{
	display: flex;
	justify-content: center;/*让子元素左右居中*/
	align-items: center;/*让子元素上下居中*/
	flex-wrap: wrap;/*让子元素会自动换行*/
	width: 100%;
	box-sizing: border-box;
	background-color: white;/**#A7B5C6*/
	padding: 20px;
	margin: 2px;
	border: 1px lightgray solid;
}

/*每个类的一个类名盒*/
.type-box .type-name-box{
	font-size: 20px;
	width: 100%;
	text-align: left;
	font-weight: 600;
	margin-bottom: 20px;
}
/*每个类的一个产品盒，里装一个或多个产品*/
.type-box .type-product-box{
	border: 0px blue solid;
	width: 100%;
	display: flex;
	justify-content: left;/*让子元素左右居中*/
	align-items: center;/*让子元素上下居中*/
	flex-wrap: wrap;/*让子元素会自动换行*/
}

/*单个产品的盒子*/
.type-box .type-product-box .p-item-box{
	width: 320px;
	height: 240px;
	padding: 30px;
	margin: 20px;
	
	border-radius: 4px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 阴影样式 */
	background-color: #c5cedc;
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中 */
	box-sizing: border-box;
	overflow: hidden;
}
.type-box .type-product-box .p-item-box:hover {
	box-shadow: 6px 6px 12px rgba(100, 180, 255, 0.6); /* 高强度悬停阴影样式 */
}

/*单个产品的图片*/
.type-box .type-product-box .p-item-box img{
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

/*每个单品下面的文本*/
.type-box .type-product-box .t-item-box{
	font-weight: 600;
	color: #00449D;/*文字颜色*/
}

/****产品详情***************************************************************/
/*大盒子*/
.detail-box{
	width: 100%;
}

/*所有的图*/
.detail-box .tu-all-box{
	display: flex;
	flex-wrap: wrap;/*让子元素会自动换行*/
	width: 100%;
}

/*单个图 +++++++++++++++++++++++++++++++++++++++++*/
.detail-box .tu-all-box .tuItem-box .item-img-box{
	width: 400px;
	height: 300px;
	background-color: #c5cedc;
	border-radius: 4px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 阴影样式 */
	font-weight: 600;
	color: #00449D;/*文字颜色*/
	
	width: 400px;
	height: 300px;
	padding: 30px;
	margin: 20px;
	
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中 */
	box-sizing: border-box;
	overflow: hidden;
}

.detail-box .tu-all-box .tuItem-box img{
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

/*产品名称*/
.detail-box .product-name{
	font-size: 30px;
	text-align: left;
	margin: 30px 0 0 30px;
	font-weight: 600;
}

/*产品名称下的小介绍*/
.detail-box .product-remark{
	font-size: 20px;
	text-align: left;
	margin-left: 30px;
	margin-top: 20px;
	font-weight: 600;
}

/*产品小介绍下的表格盒子*/
.detail-box .product-table-box{
	width: 1300px;
	border: 1px black solid;
	margin: 20px 0 50px 30px;
}
/*一行（里有两格）*/
.detail-box .row{
	font-size: 19px;
	height: 45px;
	display: flex;
	line-height: 45px;
}
.detail-box .row .cell-left{
	border: 1px black solid;
	width: 200px;
	//font-weight: 700;
}
.detail-box .row .cell-left-k{
	border: 1px black solid;
	border-left: none;
	border-right: none;
	width: 200px;
	font-size: 22px;
	font-weight: 700;
	color: blue;
}

.detail-box .row .cell-right-item{
	border: 1px black solid;
	flex: 1;
	padding-left: 10px;
}
.detail-box .row .cell-right-item-k{
	border: 1px black solid;
	border-left: none;
	border-right: none;
	flex: 1;
	padding-left: 10px;
	font-size: 22px;
	font-weight: 700;
	color: blue;
}
</style>
